.deacon__footer {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-mobile {
        margin-top: 0;
    }

    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 537px;
        background-color: $black;

        @include for-mobile {
            height: auto;
        }
    }

    &-logo {
        margin-top: 100px;

        @include for-mobile {
            margin-top: 40px;
            height: 60px;
        }
    }

    &-title {
        @include h3-heading;
        margin: 30px 0;
        color: $white;

        @include for-mobile {
            @include h4-heading;
            text-align: center;
        }
    }

    &-image {
        width: 90%;
        height: 100%;

        &-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: -60px;
            z-index: 1;

            @include for-medium {
                margin-top: 40px;
            }
            @include for-mobile {
                display: none;
            }
        }
    }

    &-info {
        &-container {
            display: flex;
            width: 85%;
            justify-content: center;

            @include for-medium {
                width: 100%;
            }

            @include for-mobile {
                flex-direction: column;
            }
        }

        &-module {
            @include body-small;
            padding: 20px;
            width: 25%;
            margin-right: 10px;
            color: $white;
            text-align: center;

            &-contact {
                width: 50%;
            }

            @include for-medium {
                width: 33.3%;

                &-contact {
                    width: 33.3%;
                }
            }

            @include for-mobile {
                width: 100%;
                margin: 0;
                margin-bottom: 40px;
                padding: 0;

                &-contact {
                    width: 100%;
                }
            }

            &-title {
                @include h4-heading;
                margin-bottom: 10px;
            }

            &-links {
                display: flex;
                justify-content: center;
                align-items: center;

                @include for-mobile {
                    flex-direction: column;

                }
            }

            &-link {
                @include h5-heading;

                text-decoration: none;
                color: $white;
                transition: all .3s ease-in-out;

                &:hover {
                    color: $goldenPear;
                }

                @include for-medium {
                    @include h6-heading;
                }

                @include for-mobile {
                    @include h5-heading;
                }
            }

            &-bullet {
                display: inline-block;
                margin: 0 20px;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: $goldenPear;

                @include for-mobile {
                    margin: 5px 20px 10px 20px;
                }
            }

            & a {
                color: $white;
            }

            &-social-icon {
                font-size: 23px;
                margin: 0 15px;
                cursor: pointer;
                transition: all .3s ease-in-out;

                &:hover {
                    color: $goldenPear;
                }
            }
        }
    }

    &-note {
        @include body-small;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: $white;

        @include for-mobile {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        &-copyright {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-credits {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @include for-mobile {
                flex-direction: column;
            }

            &-right {
                margin-left: 10px;
                @include for-mobile {
                    margin-left: 0;
                }
            }
        }

        & a {
            text-decoration: none;
            color: $white;
            transition: all .3s ease-in-out;

            &:hover {
                color: $goldenPear;
            }
        }
    }
}
