.deacon__contact {
    width: 100%;
    margin-top: 60px;

    @include for-mobile {
        width: 90%;
        height: auto;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-header {
        @include h2-heading;
        width: 70%;
        margin-bottom: 40px;
        text-align: center;

        @include for-mobile {
            @include h3-heading;
            margin-top: 20px;
            margin-bottom: 60px;
            font-size: 35px;
            width: 100%;
            text-align: center;
        }
    }

    &-info {
        &-container {
            display: flex;
            height: 150px;
            width: 90%;
            justify-content: center;

            @include for-mobile {
                flex-direction: column;
                width: 100%;
                height: inherit;
                align-items: center;
            }
        }

        &-location {
            padding: 20px;
            width: 30%;
            margin-right: 10px;
            background-image: url("../assets/arches/Arch-mobile-location.svg");
            background-position: center;
            background-repeat: no-repeat;

            @include for-mobile {
                width: 100%;
                margin: 0;
                margin-bottom: 40px;
                padding: 0;
                background: none;
            }

            &-title {
                @include contact-title($blueDark);
            }

            &-street,
            &-locale {
                @include body-small;
                text-align: center;
            }
        }

        &-general {
            padding: 20px;
            width: 30%;
            margin-right: 10px;
            background-image: url("../assets/arches/Arch-mobile-general.svg");
            background-position: center;
            background-repeat: no-repeat;

            @include for-mobile {
                width: 100%;
                margin: 0;
                margin-bottom: 40px;
                padding: 0;
                background: none;
            }

            &-title {
                @include contact-title($goldenPear);
            }
        }

        &-press {
            padding: 20px;
            width: 30%;
            background-image: url("../assets/arches/Arch-mobile-press.svg");
            background-position: center;
            background-repeat: no-repeat;

            @include for-mobile {
                width: 100%;
                margin: 0;
                margin-bottom: 40px;
                padding: 0;
                background: none;
            }

            &-title {
                @include contact-title($lavenderDark);
            }
        }

        &-body {
            @include body-small;
            text-align: center;
        }
    }

    &-button {
        @include primary-button(
            $black,
            $white,
            $terracottaDark,
            $white,
            $terracottaDark
        );
        width: 210px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        @include for-mobile {
            width: 100%;
        }
    }

    &-newsletter {
        &-container {
            width: 90%;
            height: 150px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 80px;
            background-color: $stone;

            @include for-mobile {
                width: 100vw;
                height: auto;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
            }
        }

        &-text {
            margin-left: 40px;
            @include for-mobile {
                margin: 20px;
            }

            &-title {
                @include h2-heading;
            }

            &-copy {
                @include body-small;
            }
        }

        &-form {
            margin-right: 40px;
            display: flex;

            @include for-mobile {
                width: 90%;
                margin: 20px 0;
                flex-direction: column;
            }

            &-input {
                @include body-small;
                width: 350px;
                margin-right: 20px;
                background: none;
                border: none;
                border-radius: none;
                border-bottom: 2px solid $black;

                &::placeholder {
                    color: $black;
                }

                @include for-mobile {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }

            &-button {
                @include primary-button(
                    $black,
                    $white,
                    $terracottaDark,
                    $white,
                    $terracottaDark
                );

                @include for-mobile {
                    width: 100%;
                }
            }
        }
    }
}
