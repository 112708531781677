///COLORS ///
$white: #ffffff;
$black: #111111;
$grey: #707070;
$terracottaDark: #d27154;
$terracottaMedium: #dc7a5b;
$terracottaLight: #eb9b82;
$stone: #f6f0e3;
$goldenPear: #c9a128;
$goldenPearLight: #efcf4f;
$lavender: #d5ddf1;
$lavenderDark: #6577a6;
$blue: #a2d7cc;
$blueDark: #58a293;

/// TEXT MIX INS //
@mixin primary-header {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 135px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}

@mixin h1-heading {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 60px;
    letter-spacing: 0.75px;
    line-height: 62px;
    text-transform: uppercase;
}

@mixin h2-heading {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 44px;
    letter-spacing: 0.5px;
    line-height: 48px;
    text-transform: uppercase;
}

@mixin h3-heading {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 32px;
    letter-spacing: 0.5px;
    line-height: 30px;
    text-transform: uppercase;
}

@mixin h4-heading {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 30px;
    text-transform: uppercase;
}

@mixin h5-heading {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 18px;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-transform: uppercase;
}

@mixin h6-heading {
    font-family: "Krana Fat A", "Helvetica";
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-transform: uppercase;
}

@mixin primary-body {
    font-family: "value-serif-bold", "Georgia";
    font-size: 32px;
    letter-spacing: 0.25px;
    line-height: 48px;
}

@mixin body-large {
    font-family: "value-serif-bold", "Georgia";
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 32px;
}

@mixin body-small {
    font-family: "value-serif-bold", "Georgia";
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 21px;
}

@mixin label {
    font-family: "value-serif-bold", "Georgia";
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 48px;
}

// ELEMENT MIXINS //
@mixin primary-button(
    $background,
    $text,
    $hover-background,
    $hover-text,
    $hover-border
) {
    @include h5-heading;

    height: 50px;
    width: 137px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    color: $text;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1.5px solid transparent;

    &:hover {
        background-color: $hover-background;
        color: $hover-text;
        border: 1.5px solid $hover-border;
    }
}

@mixin round-button($background, $text, $hover-background, $hover-text) {
    @include h5-heading;

    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $background;
    color: $text;
    text-decoration: none;
    text-align: center;
    transition: all 0.3s ease-in-out;

    @include for-mobile {
        height: 80px;
        width: 80px;
    }

    &:hover {
        background-color: $hover-background;
        color: $hover-text;
    }
}

@mixin contact-title($color) {
    @include h3-heading;
    text-align: center;
    color: $color;
    margin-bottom: 5px;
}

// BREAKPOINTS
@mixin for-mobile {
    @media (max-width: 900px) {
        @content;
    }
}
@mixin for-medium {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin for-large {
    @media (max-width: 1300) {
        @content;
    }
}

@mixin between-small-large {
    @media (min-width: 900px) and (max-width: 1300) {
        @content;
    }
}
