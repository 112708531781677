.deacon__top-nav {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;

    @include for-mobile {
        display: none;
    }

    &-option {
        @include h5-heading;
        margin-right: 20px;
        border: none;
        background: none;
        cursor: pointer;
        transition: all .3s ease-in-out;
        text-decoration: none;
        color: $black;

        &:hover {
            color: $white;
        }

        &-link {
            &-container {
                display: flex;
                align-items: center;
            }
        }
    }

    &-book-now {
        @include round-button($stone, $black, $black, $stone);

        @include for-mobile {
            display: none;
        }
    }
}
