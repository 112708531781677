.deacon-about {
    max-width: 100%;
    background-color: $stone;
    background-image: url("../assets/icons/Deacon-Logo-Vertical_M.svg");
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;

    @include for-mobile {
        background-image: none;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__slider {
        width: 100%;

        &-image {
            width: 100%;
        }
    }
    &__images {
        display: flex;
        margin: 40px 60px 60px 60px;
        width: 90%;

        @include for-medium {
            width: 100%;
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
        }

        &-left {
            margin-right: 40px;
            width: 50%;
            align-self: center;

            @include for-medium {
                width: 40%;
            }
        }

        &-right {
            width: 50%;
            align-self: center;

            @include for-medium {
                width: 40%;
            }
        }
    }

    &__copy {
        width: 90%;
        margin: 0 60px 40px 60px;
        display: flex;
        flex-direction: row;

        @include for-medium {
            width: 90%;
            margin: 0 0 40px 0;
            padding: 0 20px;
        }

        @include for-mobile {
            background-image: url("../assets/icons/Deacon-Logo-Vertical_M.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: inherit;
            flex-direction: column;
            align-items: center;
            margin: 0;
            padding: 0 20px;
        }

        &-description {
            width: 60%;

            @include for-medium {
                width: 60%;
            }

            @include for-mobile {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &-title {
                @include h2-heading;

                @include for-mobile {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    margin-top: 40px;
                }
            }

            &-body {
                @include body-large;
                padding-right: 30px;
                margin-bottom: 20px;

                @include for-medium {
                    @include body-small;
                    // text-align: center;
                    padding-right: 0;
                }

                @include for-mobile {
                    text-align: left;
                }
            }
        }

        &-details {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-left: 2px solid $goldenPear;

            @include for-medium {
                width: 40%;
                justify-content: space-between;
            }

            @include for-mobile {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 40px;
                border-left: none;
                flex-direction: column;
                margin-top: 40px;
                margin-bottom: 0;
            }

            &-container {
                margin-left: 20px;
                width: 40%;

                @include for-mobile {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 40px;
                }

                &-title {
                    @include h4-heading;
                    color: $goldenPear;

                    @include for-mobile {
                        @include h3-heading;
                    }
                }

                &-body {
                    @include body-small;
                }
            }
        }
    }

    &__button {
        @include primary-button(
            $black,
            $white,
            $terracottaDark,
            $white,
            $terracottaDark
        );
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        @include for-medium {
            width: 50%;
        }

        @include for-mobile {
            width: 100%;
        }
    }
}
