.deacon__map {
    width: 100%;
    height: max-content;
    display: flex;
    background-color: $stone;

    @include for-medium {
        display: none;
    }

    &-image {
        &-container {
            width: 65%;
        }

        width: 100%;
        height: 100%;
    }

    &-copy {
        width: 35%;
        &-container {
            margin: 30px;
            margin-bottom: 0;
        }

        &-title {
            @include h2-heading;

            margin-bottom: 30px;
        }

        &-category {
            &-title {
                @include h4-heading;
                color: $goldenPear;
            }

            &-list {
                @include body-small;
                margin-bottom: 30px;
            }
        }
    }

}
