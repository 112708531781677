.deacon-splash {
    width: 100%;
    min-height: 100vh;
    background-color: $terracottaDark;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        height: inherit;
        margin-top: 116px;
        margin-left: 40px;

        @include for-mobile {
            margin: 0 20px;
        }
    }

    &__logo {
        position: absolute;
        top: 40px;
        left: 40px;

        @include for-mobile {
            top: 20px;
            left: 20px;
        }
    }

    &__arrow {
        position: absolute;
        bottom: 40px;
        right: 40px;
        cursor: pointer;

        @include for-mobile {
            display: none;
        }

        /* bounce animation */
        &.animated {
            animation-duration: 1.5s;
        }
    }

    &__content-container {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__hero {
        @include primary-header;
        margin-bottom: 20px;

        @include for-mobile {
            font-size: 63px;
        }
        &-animated {
            color: $stone;
            text-decoration: underline;
            text-decoration-color: $black;
            -webkit-text-decoration-color: $black;

            @include for-medium {
                display: block;
                width: max-content;
            }
        }
    }

    &__intro {
        @include body-large;
        width: 35%;
        margin-bottom: 20px;

        @include for-mobile {
            width: 100%;
            @include body-small;
        }
    }

    &__button {
        @include primary-button($stone, $black, transparent, $stone, $stone);

        @include for-mobile {
            width: 100%;
        }
    }

    &__outro {
        @include h2-heading;
        margin: 40px 0 100px 0;
        width: 80%;
        text-align: center;

        &-opening {
            border-bottom: 6px solid $goldenPear;
        }

        @include for-medium {
            display: none;
        }
    }
}
