.deacon__history {
    padding: 0 40px;
    margin: 60px 0;

    @include for-medium {
        margin-bottom: 0;
    }

    @include for-mobile {
        display: none;
    }

    @include for-large {
        height: 520px;
    }

    @media (min-width: 900px) and (max-width: 1250px) {
        padding: 0 60px;
    }


    &-container {
        display: flex;
        flex-direction: row;

        @media (min-width: 900px) and (max-width: 1250px) {
            flex-direction: column;
        }

    }

    &-image {
        @media (min-width: 900px) and (max-width: 1250px) {
            width: 100%;
            max-width: 1250px;
        }

        @include for-large {
            max-width: 745px;
        }
    }

    &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $blue;

        @media (min-width: 900px) and (max-width: 1250px) {
            text-align: center;
            padding: 40px 0;
        }

        &-title,
        &-copy {
            width: 70%;
        }

        &-title {
            @include h2-heading;
            color: $blueDark;
        }

        &-copy {
            @include body-small;
        }
    }
}
