.deacon-content-module {
    padding: 0 40px;
    margin: 60px 0;
    height: 424px;

    @include for-medium {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &.CELEBRATE {
            background-image: url('../assets/arches/Celebrate/Large.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &__image {
        max-height: 424px;
        &.right {
            margin-right: 20px;
        }
        &.left {
            margin-left: 20px;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-title,
        &-copy {
            width: 70%;
        }

        &-title {
            @include h2-heading;

            &.CELEBRATE {
                color: $terracottaDark;
            }

            &.RELAX {
                color: $lavenderDark;
            }

            &.WORK {
                color: $blueDark;
            }
        }

        &-copy {
            @include body-small;
        }

        &.RELAX {
            background-image: url('../assets/arches/Relax/Large.svg');
            background-position: center;
            background-repeat: no-repeat;
        }

        &.WORK {
            background-image: url('../assets/arches/Work/Large.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}
