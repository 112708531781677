.modal {
    background-color: rgba(92, 46, 32, 0.9);
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
        @include body-small;
        position: relative;
        width: 50%;
        max-width: 669px;
        background-color: $stone;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
            margin: 0;
            margin-bottom: 20px;
        }

        @include for-mobile {
            padding: 20px;
            width: 100%;
            max-width: 100%;
            margin: 0 15px;
            font-size: 12px;
        }

        &--header {
            @include h2-heading;
            color: $terracottaDark;
            margin: 0;
            margin-bottom: 20px;

            @include for-mobile {
                @include h3-heading;
                margin-top: 40px;
            }
        }

        &--button {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            background: none;
            border: none;
            margin: 0;
            padding: 0;
            cursor: pointer;

            &:hover {
                color: $terracottaDark;

                * {
                    fill: currentColor;
                }
            }

            @include for-mobile {
                width: 15px;
                height: 15px;
            }

            svg {
                width: 100%;
            }
        }
    }
}
